import { APP_INITIALIZER, LOCALE_ID, enableProdMode, importProvidersFrom } from '@angular/core';

import { CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Title, bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {
  NgbActiveModal,
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbDatepickerModule,
  NgbModule,
} from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { L10nIntlModule, L10nLoader, L10nTranslationModule } from 'angular-l10n';
import { NgxCaptchaModule } from 'ngx-captcha';
import { NgxFileDropModule } from 'ngx-file-drop';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { ToastrModule } from 'ngx-toastr';
import { AppComponent } from './app/app.component';
import { ErrorHandlingModule, HttpTranslationLoader, initL10n, l10nConfig } from './app/app.module';
import { AppRoutingModule } from './app/app.routing';
import { CustomToastComponent } from './app/core/components/custom-toast/custom-toast.component';
import { AdService } from './app/core/components/painel-ad/ad.service';
import { AdjustZIndexDirective } from './app/core/directives/adjust-z-index.directive';
import { AutofocusDirective } from './app/core/directives/autofocus.directive';
import { CharCountDirective } from './app/core/directives/char-count.directive';
import { DateHourMaskDirective } from './app/core/directives/date-hour-mask.directive';
import { DateMaskDirective } from './app/core/directives/date-mask.directive';
import { DragDropFileUploadDirective } from './app/core/directives/drag-drop-file-upload.directive';
import { HourMaskDirective } from './app/core/directives/hour-mask.directive';
import { IndeterminateDirective } from './app/core/directives/indeterminate.directive';
import { KzMaskCurrencyDirective } from './app/core/directives/kz-mask-currency.directive';
import { NumberOnlyDirective } from './app/core/directives/number-only.directive';
import { UppercaseInputDirective } from './app/core/directives/uppercase-input.directive';
import { NgbDatePTParserFormatter } from './app/core/helpers/NgbDatePTParserFormatter';
import { AuthGuard } from './app/core/helpers/auth.guard';
import { CpfCnpjValidatorDirective } from './app/core/helpers/cpf-cnpj.validator.directive';
import { NgbDateStringAdapter } from './app/core/helpers/ngb-date-string-adapter';
import { AlertService } from './app/core/services/alert.service';
import { AuthenticationService } from './app/core/services/authentication.service';
import { DataService } from './app/core/services/data.service';
import { FavoritosService } from './app/core/services/favoritos.service';
import { AuthenticationImplService } from './app/core/services/impl/authentication-impl.service';
import { UtilsImplService } from './app/core/services/impl/utils-impl.service';
import { MessageService } from './app/core/services/message.service';
import { SelectOptionService } from './app/core/services/select-option.service';
import { TitlePanelService } from './app/core/services/title-panel.service';
import { UploadFileService } from './app/core/services/upload-file.service';
import { UtilsService } from './app/core/services/utils.service';
import { AdminModule } from './app/modules/admin/admin.module';
import { environment } from './environments/environment';
import { BrokerModuleProfileService } from './app/core/services/broker-module-profile.service';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      CommonModule,
      FormsModule,
      ErrorHandlingModule.forRoot(),
      ReactiveFormsModule,
      NgbDatepickerModule,
      NgSelectModule,
      NgxCaptchaModule,
      L10nTranslationModule.forRoot(l10nConfig, {
        //storage: AppStorage,
        translationLoader: HttpTranslationLoader,
      }),
      L10nIntlModule,
      ToastrModule.forRoot({
        toastComponent: CustomToastComponent,
        preventDuplicates: true,
      }),
      LoadingBarHttpClientModule,
      LoadingBarRouterModule,
      NgbModule,
      AppRoutingModule,
      NgxFileDropModule,
      NgxMaskDirective,
      NgxMaskPipe,
      NumberOnlyDirective,
      AutofocusDirective,
      DateHourMaskDirective,
      AdjustZIndexDirective,
      DateMaskDirective,
      DragDropFileUploadDirective,
      HourMaskDirective,
      IndeterminateDirective,
      KzMaskCurrencyDirective,
      UppercaseInputDirective,
      CpfCnpjValidatorDirective,
      CharCountDirective,
    ),
    AuthGuard,
    AlertService,
    AdService,
    MessageService,
    FavoritosService,
    DataService,
    SelectOptionService,
    TitlePanelService,
    BrokerModuleProfileService,
    Title,
    NgbActiveModal,
    {
      provide: APP_INITIALIZER,
      useFactory: initL10n,
      deps: [L10nLoader],
      multi: true,
    },
    UploadFileService,
    provideNgxMask(),
    { provide: NgbDateAdapter, useClass: NgbDateStringAdapter },
    { provide: NgbDateParserFormatter, useClass: NgbDatePTParserFormatter },
    Location,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: LOCALE_ID, useValue: 'pt' },
    { provide: AuthenticationService, useClass: AuthenticationImplService },
    { provide: UtilsService, useClass: UtilsImplService },
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    importProvidersFrom(AdminModule.forRoot()),
    provideAnimationsAsync(),
  ],
}).catch((err) => console.log(err));
