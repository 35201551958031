import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AdPainelDescription } from '../../models/AdPainelDescription';

import { ChangePasswordFormComponent } from 'src/app/modules/admin/components/user/change-password-form/change-password-form.component';
import { UserProfileFormComponent } from 'src/app/modules/admin/components/user/user-profile-form/user-profile-form.component';
import { AdDadosElement } from './ad-dados_element';
import { AdItem } from './ad-item';
import { HeroJobAdComponent } from './hero-job-ad.component';

export interface AdEvent {
  cmd?: 'CLEAR' | 'ADD';
  description?: AdPainelDescription;
}

@Injectable()
export class AdService {
  private eventSubject = new Subject<AdEvent>();

  private adsList: AdItem[] = [];

  constructor() {}

  public getObservableEvent(): Observable<AdEvent> {
    return this.eventSubject.asObservable();
  }

  public static getAdsPainel(description: AdPainelDescription): AdItem[] {
    const lista: AdItem[] = [];
    if (description.cards) {
      let id = 1;
      description.cards.forEach((element) => {
        const data: AdDadosElement = { id: id, fragment: true, painel: description.href, card: element };
        switch (element.name) {
          // {{INSERIR-CODIGO-AQUI}}
          case 'app-user-profile-form':
            lista.push(new AdItem(UserProfileFormComponent, data));
            break;
          case 'app-change-password':
            lista.push(new AdItem(ChangePasswordFormComponent, data));
            break;
          default:
            lista.push(new AdItem(HeroJobAdComponent, data));
            break;
        }
        id++;
      });
    }
    return lista;
  }

  public clear(href: string): void {
    this.adsList.splice(0, this.adsList.length);
    this.eventSubject.next({ cmd: 'CLEAR', description: { href: href } });
  }

  public add(description: AdPainelDescription): void {
    const ads = AdService.getAdsPainel(description);
    if (ads) {
      ads.forEach((element) => {
        this.adsList.push(element);
      });
    }
    this.eventSubject.next({ cmd: 'ADD', description: description });
  }

  public getAdsView(): AdItem[] {
    return this.adsList;
  }
}
