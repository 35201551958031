/* eslint-disable @typescript-eslint/no-explicit-any */
import { formatDate } from '@angular/common';
import { HttpErrorResponse, HttpResponse, HttpResponseBase } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { L10nTranslationService } from 'angular-l10n';
import { LoaderModalComponent } from '../components/loader/loader-modal.component';
import { ViewTypeEnum } from '../helpers/ViewTypeEnum';
import { ModalCallbackFunction } from '../models/CloseModalResult';
import { ValueName } from '../models/ValueName';
import { GridModelComponent } from '../models/grid-model.component';
import { AlertService } from './alert.service';
import { BagParameter } from '../models/BagParameter';
//declare function StartModalFlutuante();
@Injectable()
export class Utilities {
  private static readonly base64map =
    'eyLDgSI6IkEiLCLEgiI6IkEiLCLhuq4iOiJBIiwi4bq2IjoiQSIsIuG6sCI6IkEiLCLhurIiOiJB' +
    'Iiwi4bq0IjoiQSIsIseNIjoiQSIsIsOCIjoiQSIsIuG6pCI6IkEiLCLhuqwiOiJBIiwi4bqmIjoiQSIsIuG6qCI6IkEiLCLhuqoiOiJBIiwiw4' +
    'QiOiJBIiwix54iOiJBIiwiyKYiOiJBIiwix6AiOiJBIiwi4bqgIjoiQSIsIsiAIjoiQSIsIsOAIjoiQSIsIuG6oiI6IkEiLCLIgiI6IkEiLCLE' +
    'gCI6IkEiLCLEhCI6IkEiLCLDhSI6IkEiLCLHuiI6IkEiLCLhuIAiOiJBIiwiyLoiOiJBIiwiw4MiOiJBIiwi6pyyIjoiQUEiLCLDhiI6IkFFIiwix7wiOiJ' +
    'BRSIsIseiIjoiQUUiLCLqnLQiOiJBTyIsIuqctiI6IkFVIiwi6py4IjoiQVYiLCLqnLoiOiJBViIsIuqcvCI6IkFZIiwi4biCIjoiQiIsIuG4h' +
    'CI6IkIiLCLGgSI6IkIiLCLhuIYiOiJCIiwiyYMiOiJCIiwixoIiOiJCIiwixIYiOiJDIiwixIwiOiJDIiwiw4ciOiJDIiwi4biIIjoiQyIsIsS' +
    'IIjoiQyIsIsSKIjoiQyIsIsaHIjoiQyIsIsi7IjoiQyIsIsSOIjoiRCIsIuG4kCI6IkQiLCLhuJIiOiJEIiwi4biKIjoiRCIsIuG4jCI6IkQiL' +
    'CLGiiI6IkQiLCLhuI4iOiJEIiwix7IiOiJEIiwix4UiOiJEIiwixJAiOiJEIiwixosiOiJEIiwix7EiOiJEWiIsIseEIjoiRFoiLCLDiSI6IkUi' +
    'LCLElCI6IkUiLCLEmiI6IkUiLCLIqCI6IkUiLCLhuJwiOiJFIiwiw4oiOiJFIiwi4bq+IjoiRSIsIuG7hiI6IkUiLCLhu4AiOiJFIiwi4buCIjoi' +
    'RSIsIuG7hCI6IkUiLCLhuJgiOiJFIiwiw4siOiJFIiwixJYiOiJFIiwi4bq4IjoiRSIsIsiEIjoiRSIsIsOIIjoiRSIsIuG6uiI6IkUiLCLIhiI6' +
    'IkUiLCLEkiI6IkUiLCLhuJYiOiJFIiwi4biUIjoiRSIsIsSYIjoiRSIsIsmGIjoiRSIsIuG6vCI6IkUiLCLhuJoiOiJFIiwi6p2qIjoiRVQiLCLh' +
    'uJ4iOiJGIiwixpEiOiJGIiwix7QiOiJHIiwixJ4iOiJHIiwix6YiOiJHIiwixKIiOiJHIiwixJwiOiJHIiwixKA' +
    'iOiJHIiwixpMiOiJHIiwi4bigIjoiRyIsIsekIjoiRyIsIuG4qiI6IkgiLCLIniI6IkgiLCLhuKgiOiJIIiwixKQiOiJIIiwi4rGnIjoiSCIsIuG' +
    '4piI6IkgiLCLhuKIiOiJIIiwi4bikIjoiSCIsIsSmIjoiSCIsIsONIjoiSSIsIsSsIjoiSSIsIsePIjoiSSIsIsOOIjoiSSIsIsOPIjoiSSIsIuG4' +
    'riI6IkkiLCLEsCI6IkkiLCLhu4oiOiJJIiwiyIgiOiJJIiwiw4wiOiJJIiwi4buIIjoiSSIsIsiKIjoiSSIsIsSqIjoiSSIsIsSuIjoiSSIsIsaXI' +
    'joiSSIsIsSoIjoiSSIsIuG4rCI6IkkiLCLqnbkiOiJEIiwi6p27IjoiRiIsIuqdvSI6IkciLCLqnoIiOiJSIiwi6p6EIjoiUyIsIuqehiI6IlQiLC' +
    'LqnawiOiJJUyIsIsS0IjoiSiIsIsmIIjoiSiIsIuG4sCI6IksiLCLHqCI6IksiLCLEtiI6IksiLCLisakiOiJLIiwi6p2CIjoiSyIsIuG4siI6Iksi' +
    'LCLGmCI6IksiLCLhuLQiOiJLIiwi6p2AIjoiSyIsIuqdhCI6IksiLCLEuSI6IkwiLCLIvSI6IkwiLCLEvSI6IkwiLCLEuyI6IkwiLCLhuLwiOiJMIi' +
    'wi4bi2IjoiTCIsIuG4uCI6IkwiLCLisaAiOiJMIiwi6p2IIjoiTCIsIuG4uiI6IkwiLCLEvyI6IkwiLCLisaIiOiJMIiwix4giOiJMIiwixYEiOiJ' +
    'MIiwix4ciOiJMSiIsIuG4viI6Ik0iLCLhuYAiOiJNIiwi4bmCIjoiTSIsIuKxriI6Ik0iLCLFgyI6Ik4iLCLFhyI6Ik4iLCLFhSI6Ik4iLCLhuYoiO' +
    'iJOIiwi4bmEIjoiTiIsIuG5hiI6Ik4iLCLHuCI6Ik4iLCLGnSI6Ik4iLCLhuYgiOiJOIiwiyKAiOiJOIiwix4siOiJOIiwiw5EiOiJOIiwix4oiO' +
    'iJOSiIsIsOTIjoiTyIsIsWOIjoiTyIsIseRIjoiTyIsIsOUIjoiTyIsIuG7kCI6Ik8iLCLhu5giOiJPIiwi4buSIjoiTyIsIuG7lCI6Ik8iLCLhu5' +
    'YiOiJPIiwiw5YiOiJPIiwiyKoiOiJPIiwiyK4iOiJPIiwiyLAiOiJPIiwi4buMIjoiTyIsIsWQIjoiTyIsIsiMIjoiTyIsIsOSIjoiTyIsIuG7jiI' +
    '6Ik8iLCLGoCI6Ik8iLCLhu5oiOiJPIiwi4buiIjoiTyIsIuG7nCI6Ik8iLCLhu54iOiJPIiwi4bugIjoiTyIsIsiOIjoiTyIsIuqdiiI6Ik8iLCLq' +
    'nYwiOiJPIiwixYwiOiJPIiwi4bmSIjoiTyIsIuG5kCI6Ik8iLCLGnyI6Ik8iLCLHqiI6Ik8iLCLHrCI6Ik8iLCLDmCI6Ik8iLCLHviI6Ik8iLCLD' +
    'lSI6Ik8iLCLhuYwiOiJPIiwi4bmOIjoiTyIsIsisIjoiTyIsIsaiIjoiT0kiLCLqnY4iOiJPTyIsIsaQIjoiRSIsIsaGIjoiTyIsIsiiIjoiT1UiL' +
    'CLhuZQiOiJQIiwi4bmWIjoiUCIsIuqdkiI6IlAiLCLGpCI6IlAiLCLqnZQiOiJQIiwi4rGjIjoiUCIsIuqdkCI6IlAiLCLqnZgiOiJRIiwi6p2WIjo' +
    'iUSIsIsWUIjoiUiIsIsWYIjoiUiIsIsWWIjoiUiIsIuG5mCI6IlIiLCLhuZoiOiJSIiwi4bmcIjoiUiIsIsiQIjoiUiIsIsiSIjoiUiIsIuG5niI6I' +
    'lIiLCLJjCI6IlIiLCLisaQiOiJSIiwi6py+IjoiQyIsIsaOIjoiRSIsIsWaIjoiUyIsIuG5pCI6IlMiLCLFoCI6IlMiLCLhuaYiOiJTIiwixZ4iOiJT' +
    'IiwixZwiOiJTIiwiyJgiOiJTIiwi4bmgIjoiUyIsIuG5oiI6IlMiLCLhuagiOiJTIiwixaQiOiJUIiwixaIiOiJUIiwi4bmwIjoiVCIsIsiaIjoiVCI' +
    'sIsi+IjoiVCIsIuG5qiI6IlQiLCLhuawiOiJUIiwixqwiOiJUIiwi4bmuIjoiVCIsIsauIjoiVCIsIsWmIjoiVCIsIuKxryI6IkEiLCLqnoAiOiJMIi' +
    'wixpwiOiJNIiwiyYUiOiJWIiwi6pyoIjoiVFoiLCLDmiI6IlUiLCLFrCI6IlUiLCLHkyI6IlUiLCLDmyI6IlUiLCLhubYiOiJVIiwiw5wiOiJVIiwix' +
    '5ciOiJVIiwix5kiOiJVIiwix5siOiJVIiwix5UiOiJVIiwi4bmyIjoiVSIsIuG7pCI6IlUiLCLFsCI6IlUiLCLIlCI6IlUiLCLDmSI6IlUiLCLhu6Yi' +
    'OiJVIiwixq8iOiJVIiwi4buoIjoiVSIsIuG7sCI6IlUiLCLhu6oiOiJVIiwi4busIjoiVSIsIuG7riI6IlUiLCLIliI6IlUiLCLFqiI6IlUiLCLhubo' +
    'iOiJVIiwixbIiOiJVIiwixa4iOiJVIiwixagiOiJVIiwi4bm4IjoiVSIsIuG5tCI6IlUiLCLqnZ4iOiJWIiwi4bm+IjoiViIsIsayIjoiViIsIuG5vC' +
    'I6IlYiLCLqnaAiOiJWWSIsIuG6giI6IlciLCLFtCI6IlciLCLhuoQiOiJXIiwi4bqGIjoiVyIsIuG6iCI6IlciLCLhuoAiOiJXIiwi4rGyIjoiVyIsIu' +
    'G6jCI6IlgiLCLhuooiOiJYIiwiw50iOiJZIiwixbYiOiJZIiwixbgiOiJZIiwi4bqOIjoiWSIsIuG7tCI6IlkiLCLhu7IiOiJZIiwixrMiOiJZIiwi4b' +
    'u2IjoiWSIsIuG7viI6IlkiLCLIsiI6IlkiLCLJjiI6IlkiLCLhu7giOiJZIiwixbkiOiJaIiwixb0iOiJaIiwi4bqQIjoiWiIsIuKxqyI6IloiLCLFuy' +
    'I6IloiLCLhupIiOiJaIiwiyKQiOiJaIiwi4bqUIjoiWiIsIsa1IjoiWiIsIsSyIjoiSUoiLCLFkiI6Ik9FIiwi4bSAIjoiQSIsIuG0gSI6IkFFIiwiy' +
    'pkiOiJCIiwi4bSDIjoiQiIsIuG0hCI6IkMiLCLhtIUiOiJEIiwi4bSHIjoiRSIsIuqcsCI6IkYiLCLJoiI6IkciLCLKmyI6IkciLCLKnCI6IkgiLCLJ' +
    'qiI6IkkiLCLKgSI6IlIiLCLhtIoiOiJKIiwi4bSLIjoiSyIsIsqfIjoiTCIsIuG0jCI6IkwiLCLhtI0iOiJNIiwiybQiOiJOIiwi4bSPIjoiTyIsIsm2I' +
    'joiT0UiLCLhtJAiOiJPIiwi4bSVIjoiT1UiLCLhtJgiOiJQIiwiyoAiOiJSIiwi4bSOIjoiTiIsIuG0mSI6IlIiLCLqnLEiOiJTIiwi4bSbIjoiVCIs' +
    'IuKxuyI6IkUiLCLhtJoiOiJSIiwi4bScIjoiVSIsIuG0oCI6IlYiLCLhtKEiOiJXIiwiyo8iOiJZIiwi4bSiIjoiWiIsIsOhIjoiYSIsIsSDIjoiYS' +
    'IsIuG6ryI6ImEiLCLhurciOiJhIiwi4bqxIjoiYSIsIuG6syI6ImEiLCLhurUiOiJhIiwix44iOiJhIiwiw6IiOiJhIiwi4bqlIjoiYSIsIuG6rSI6Im' +
    'EiLCLhuqciOiJhIiwi4bqpIjoiYSIsIuG6qyI6ImEiLCLDpCI6ImEiLCLHnyI6ImEiLCLIpyI6ImEiLCLHoSI6ImEiLCLhuqEiOiJhIiwiyIEiOiJhI' +
    'iwiw6AiOiJhIiwi4bqjIjoiYSIsIsiDIjoiYSIsIsSBIjoiYSIsIsSFIjoiYSIsIuG2jyI6ImEiLCLhupoiOiJhIiwiw6UiOiJhIiwix7siOiJhIiwi' +
    '4biBIjoiYSIsIuKxpSI6ImEiLCLDoyI6ImEiLCLqnLMiOiJhYSIsIsOmIjoiYWUiLCLHvSI6ImFlIiwix6MiOiJhZSIsIuqctSI6ImFvIiwi6py3Ij' +
    'oiYXUiLCLqnLkiOiJhdiIsIuqcuyI6ImF2Iiwi6py9IjoiYXkiLCLhuIMiOiJiIiwi4biFIjoiYiIsIsmTIjoiYiIsIuG4hyI6ImIiLCLhtawiOiJi' +
    'Iiwi4baAIjoiYiIsIsaAIjoiYiIsIsaDIjoiYiIsIsm1IjoibyIsIsSHIjoiYyIsIsSNIjoiYyIsIsOnIjoiYyIsIuG4iSI6ImMiLCLEiSI6ImMiLCL' +
    'JlSI6ImMiLCLEiyI6ImMiLCLGiCI6ImMiLCLIvCI6ImMiLCLEjyI6ImQiLCLhuJEiOiJkIiwi4biTIjoiZCIsIsihIjoiZCIsIuG4iyI6ImQiLCLhuI0i' +
    'OiJkIiwiyZciOiJkIiwi4baRIjoiZCIsIuG4jyI6ImQiLCLhta0iOiJkIiwi4baBIjoiZCIsIsSRIjoiZCIsIsmWIjoiZCIsIsaMIjoiZCIsIsSx' +
    'IjoiaSIsIsi3IjoiaiIsIsmfIjoiaiIsIsqEIjoiaiIsIsezIjoiZHoiLCLHhiI6ImR6Iiwiw6kiOiJlIiwixJUiOiJlIiwixJsiOiJlIiwiyKkiO' +
    'iJlIiwi4bidIjoiZSIsIsOqIjoiZSIsIuG6vyI6ImUiLCLhu4ciOiJlIiwi4buBIjoiZSIsIuG7gyI6ImUiLCLhu4UiOiJlIiwi4biZIjoiZSIsIsOr' +
    'IjoiZSIsIsSXIjoiZSIsIuG6uSI6ImUiLCLIhSI6ImUiLCLDqCI6ImUiLCLhursiOiJlIiwiyIciOiJlIiwixJMiOiJlIiwi4biXIjoiZSIsIuG4lSI' +
    '6ImUiLCLisbgiOiJlIiwixJkiOiJlIiwi4baSIjoiZSIsIsmHIjoiZSIsIuG6vSI6ImUiLCLhuJsiOiJlIiwi6p2rIjoiZXQiLCLhuJ8iOiJmIiwixp' +
    'IiOiJmIiwi4bWuIjoiZiIsIuG2giI6ImYiLCLHtSI6ImciLCLEnyI6ImciLCLHpyI6ImciLCLEoyI6ImciLCLEnSI6ImciLCLEoSI6ImciLCLJoCI6' +
    'ImciLCLhuKEiOiJnIiwi4baDIjoiZyIsIselIjoiZyIsIuG4qyI6ImgiLCLInyI6ImgiLCLhuKkiOiJoIiwixKUiOiJoIiwi4rGoIjoiaCIsIuG4pyI6' +
    'ImgiLCLhuKMiOiJoIiwi4bilIjoiaCIsIsmmIjoiaCIsIuG6liI6ImgiLCLEpyI6ImgiLCLGlSI6Imh2Iiwiw60iOiJpIiwixK0iOiJpIiwix5AiOiJpI' +
    'iwiw64iOiJpIiwiw68iOiJpIiwi4bivIjoiaSIsIuG7iyI6ImkiLCLIiSI6ImkiLCLDrCI6ImkiLCLhu4kiOiJpIiwiyIsiOiJpIiwixKsiOiJpIiwi' +
    'xK8iOiJpIiwi4baWIjoiaSIsIsmoIjoiaSIsIsSpIjoiaSIsIuG4rSI6ImkiLCLqnboiOiJkIiwi6p28IjoiZiIsIuG1uSI6ImciLCLqnoMiOiJyIiwi' +
    '6p6FIjoicyIsIuqehyI6InQiLCLqna0iOiJpcyIsIsewIjoiaiIsIsS1IjoiaiIsIsqdIjoiaiIsIsmJIjoiaiIsIuG4sSI6ImsiLCLHqSI6ImsiLCLEt' +
    'yI6ImsiLCLisaoiOiJrIiwi6p2DIjoiayIsIuG4syI6ImsiLCLGmSI6ImsiLCLhuLUiOiJrIiwi4baEIjoiayIsIuqdgSI6ImsiLCLqnYUiOiJrIiwixLo' +
    'iOiJsIiwixpoiOiJsIiwiyawiOiJsIiwixL4iOiJsIiwixLwiOiJsIiwi4bi9IjoibCIsIsi0IjoibCIsIuG4tyI6ImwiLCLhuLkiOiJsIiwi4rGhIjoib' +
    'CIsIuqdiSI6ImwiLCLhuLsiOiJsIiwixYAiOiJsIiwiyasiOiJsIiwi4baFIjoibCIsIsmtIjoibCIsIsWCIjoibCIsIseJIjoibGoiLCLFvyI6InMiLC' +
    'LhupwiOiJzIiwi4bqbIjoicyIsIuG6nSI6InMiLCLhuL8iOiJtIiwi4bmBIjoibSIsIuG5gyI6Im0iLCLJsSI6Im0iLCLhta8iOiJtIiwi4baGIjoibSIs' +
    'IsWEIjoibiIsIsWIIjoibiIsIsWGIjoibiIsIuG5iyI6Im4iLCLItSI6Im4iLCLhuYUiOiJuIiwi4bmHIjoibiIsIse5IjoibiIsIsmyIjoibiIsIuG5' +
    'iSI6Im4iLCLGniI6Im4iLCLhtbAiOiJuIiwi4baHIjoibiIsIsmzIjoibiIsIsOxIjoibiIsIseMIjoibmoiLCLDsyI6Im8iLCLFjyI6Im8iLCLHkiI6I' +
    'm8iLCLDtCI6Im8iLCLhu5EiOiJvIiwi4buZIjoibyIsIuG7kyI6Im8iLCLhu5UiOiJvIiwi4buXIjoibyIsIsO2IjoibyIsIsirIjoibyIsIsivIjoi' +
    'byIsIsixIjoibyIsIuG7jSI6Im8iLCLFkSI6Im8iLCLIjSI6Im8iLCLDsiI6Im8iLCLhu48iOiJvIiwixqEiOiJvIiwi4bubIjoibyIsIuG7oyI6Im8i' +
    'LCLhu50iOiJvIiwi4bufIjoibyIsIuG7oSI6Im8iLCLIjyI6Im8iLCLqnYsiOiJvIiwi6p2NIjoibyIsIuKxuiI6Im8iLCLFjSI6Im8iLCLhuZMiOiJvI' +
    'iwi4bmRIjoibyIsIserIjoibyIsIsetIjoibyIsIsO4IjoibyIsIse/IjoibyIsIsO1IjoibyIsIuG5jSI6Im8iLCLhuY8iOiJvIiwiyK0iOiJvIiwix' +
    'qMiOiJvaSIsIuqdjyI6Im9vIiwiyZsiOiJlIiwi4baTIjoiZSIsIsmUIjoibyIsIuG2lyI6Im8iLCLIoyI6Im91Iiwi4bmVIjoicCIsIuG5lyI6InAiLC' +
    'LqnZMiOiJwIiwixqUiOiJwIiwi4bWxIjoicCIsIuG2iCI6InAiLCLqnZUiOiJwIiwi4bW9IjoicCIsIuqdkSI6InAiLCLqnZkiOiJxIiwiyqAiOiJxIiw' +
    'iyYsiOiJxIiwi6p2XIjoicSIsIsWVIjoiciIsIsWZIjoiciIsIsWXIjoiciIsIuG5mSI6InIiLCLhuZsiOiJyIiwi4bmdIjoiciIsIsiRIjoiciIsIsm+I' +
    'joiciIsIuG1syI6InIiLCLIkyI6InIiLCLhuZ8iOiJyIiwiybwiOiJyIiwi4bWyIjoiciIsIuG2iSI6InIiLCLJjSI6InIiLCLJvSI6InIiLCLihoQiOiJjI' +
    'iwi6py/IjoiYyIsIsmYIjoiZSIsIsm/IjoiciIsIsWbIjoicyIsIuG5pSI6InMiLCLFoSI6InMiLCLhuaciOiJzIiwixZ8iOiJzIiwixZ0iOiJzIiwiyJkiO' +
    'iJzIiwi4bmhIjoicyIsIuG5oyI6InMiLCLhuakiOiJzIiwiyoIiOiJzIiwi4bW0IjoicyIsIuG2iiI6InMiLCLIvyI6InMiLCLJoSI6ImciLCLhtJEiOiJvI' +
    'iwi4bSTIjoibyIsIuG0nSI6InUiLCLFpSI6InQiLCLFoyI6InQiLCLhubEiOiJ0IiwiyJsiOiJ0IiwiyLYiOiJ0Iiwi4bqXIjoidCIsIuKxpiI6InQiLCLhua' +
    'siOiJ0Iiwi4bmtIjoidCIsIsatIjoidCIsIuG5ryI6InQiLCLhtbUiOiJ0IiwixqsiOiJ0IiwiyogiOiJ0IiwixaciOiJ0Iiwi4bW6IjoidGgiLCLJkCI6ImEi' +
    'LCLhtIIiOiJhZSIsIsedIjoiZSIsIuG1tyI6ImciLCLJpSI6ImgiLCLKriI6ImgiLCLKryI6ImgiLCLhtIkiOiJpIiwiyp4iOiJrIiwi6p6BIjoibCIsIsmvIjo' +
    'ibSIsIsmwIjoibSIsIuG0lCI6Im9lIiwiybkiOiJyIiwiybsiOiJyIiwiyboiOiJyIiwi4rG5IjoiciIsIsqHIjoidCIsIsqMIjoidiIsIsqNIjoidyIsIsqOI' +
    'joieSIsIuqcqSI6InR6Iiwiw7oiOiJ1Iiwixa0iOiJ1Iiwix5QiOiJ1Iiwiw7siOiJ1Iiwi4bm3IjoidSIsIsO8IjoidSIsIseYIjoidSIsIseaIjoidSIsI' +
    'secIjoidSIsIseWIjoidSIsIuG5syI6InUiLCLhu6UiOiJ1IiwixbEiOiJ1IiwiyJUiOiJ1Iiwiw7kiOiJ1Iiwi4bunIjoidSIsIsawIjoidSIsIuG7q' +
    'SI6InUiLCLhu7EiOiJ1Iiwi4burIjoidSIsIuG7rSI6InUiLCLhu68iOiJ1IiwiyJciOiJ1IiwixasiOiJ1Iiwi4bm7IjoidSIsIsWzIjoidSIsIuG2m' +
    'SI6InUiLCLFryI6InUiLCLFqSI6InUiLCLhubkiOiJ1Iiwi4bm1IjoidSIsIuG1qyI6InVlIiwi6p24IjoidW0iLCLisbQiOiJ2Iiwi6p2fIjoidiIs' +
    'IuG5vyI6InYiLCLKiyI6InYiLCLhtowiOiJ2Iiwi4rGxIjoidiIsIuG5vSI6InYiLCLqnaEiOiJ2eSIsIuG6gyI6InciLCLFtSI6InciLCLhuoUiOiJ3' +
    'Iiwi4bqHIjoidyIsIuG6iSI6InciLCLhuoEiOiJ3Iiwi4rGzIjoidyIsIuG6mCI6InciLCLhuo0iOiJ4Iiwi4bqLIjoieCIsIuG2jSI6IngiLCLDvSI6I' +
    'nkiLCLFtyI6InkiLCLDvyI6InkiLCLhuo8iOiJ5Iiwi4bu1IjoieSIsIuG7syI6InkiLCLGtCI6InkiLCLhu7ciOiJ5Iiwi4bu/IjoieSIsIsizIjoie' +
    'SIsIuG6mSI6InkiLCLJjyI6InkiLCLhu7kiOiJ5IiwixboiOiJ6Iiwixb4iOiJ6Iiwi4bqRIjoieiIsIsqRIjoieiIsIuKxrCI6InoiLCLFvCI6Inoi' +
    'LCLhupMiOiJ6IiwiyKUiOiJ6Iiwi4bqVIjoieiIsIuG1tiI6InoiLCLhto4iOiJ6IiwiypAiOiJ6IiwixrYiOiJ6IiwiyYAiOiJ6Iiwi76yAIjoiZmYiLC' +
    'LvrIMiOiJmZmkiLCLvrIQiOiJmZmwiLCLvrIEiOiJmaSIsIu+sgiI6ImZsIiwixLMiOiJpaiIsIsWTIjoib2UiLCLvrIYiOiJzdCIsIuKCkCI6ImEiLCLi' +
    'gpEiOiJlIiwi4bWiIjoiaSIsIuKxvCI6ImoiLCLigpIiOiJvIiwi4bWjIjoiciIsIuG1pCI6InUiLCLhtaUiOiJ2Iiwi4oKTIjoieCJ9';

  public static latinMap = null;

  public static readonly captionAndMessageSeparator = ':';
  public static readonly noNetworkMessageCaption = 'No Network';
  public static readonly noNetworkMessageDetail = 'The server cannot be reached';
  public static readonly accessDeniedMessageCaption = 'Access Denied!';
  public static readonly accessDeniedMessageDetail = '';

  public static cookies = {
    getItem: (sKey) => {
      return (
        decodeURIComponent(
          document.cookie.replace(
            new RegExp(
              '(?:(?:^|.*;)\\s*' + encodeURIComponent(sKey).replace(/[-.+*]/g, '\\$&') + '\\s*\\=\\s*([^;]*).*$)|^.*$',
            ),
            '$1',
          ),
        ) || null
      );
    },
    setItem: (sKey, sValue, vEnd, sPath, sDomain, bSecure) => {
      if (!sKey || /^(?:expires|max-age|path|domain|secure)$/i.test(sKey)) {
        return false;
      }
      let sExpires = '';
      if (vEnd) {
        switch (vEnd.constructor) {
          case Number:
            sExpires = vEnd === Infinity ? '; expires=Fri, 31 Dec 9999 23:59:59 GMT' : '; max-age=' + vEnd;
            break;
          case String:
            sExpires = '; expires=' + vEnd;
            break;
          case Date:
            sExpires = '; expires=' + vEnd.toUTCString();
            break;
        }
      }
      document.cookie =
        encodeURIComponent(sKey) +
        '=' +
        encodeURIComponent(sValue) +
        sExpires +
        (sDomain ? '; domain=' + sDomain : '') +
        (sPath ? '; path=' + sPath : '') +
        (bSecure ? '; secure' : '');
      return true;
    },
    removeItem: (sKey, sPath, sDomain) => {
      if (!sKey) {
        return false;
      }
      document.cookie =
        encodeURIComponent(sKey) +
        '=; expires=Thu, 01 Jan 1970 00:00:00 GMT' +
        (sDomain ? '; domain=' + sDomain : '') +
        (sPath ? '; path=' + sPath : '');
      return true;
    },
    hasItem: (sKey) => {
      return new RegExp('(?:^|;\\s*)' + encodeURIComponent(sKey).replace(/[-.+*]/g, '\\$&') + '\\s*\\=').test(
        document.cookie,
      );
    },
    keys: () => {
      const aKeys = document.cookie
        .replace(/((?:^|\s*;)[^=]+)(?=;|$)|^\s*|\s*(?:=[^;]*)?(?:1|$)/g, '')
        .split(/\s*(?:=[^;]*)?;\s*/);
      for (let nIdx = 0; nIdx < aKeys.length; nIdx++) {
        aKeys[nIdx] = decodeURIComponent(aKeys[nIdx]);
      }
      return aKeys;
    },
  };

  public static getHttpResponseMessage(data: HttpResponseBase | any): string[] {
    const responses: string[] = [];
    if (data instanceof HttpResponseBase) {
      if (this.checkNoNetwork(data)) {
        responses.push(
          `${this.noNetworkMessageCaption}${this.captionAndMessageSeparator} ${this.noNetworkMessageDetail}`,
        );
      } else {
        const responseObject = this.getResponseBody(data);
        if (responseObject && (typeof responseObject === 'object' || responseObject instanceof Object)) {
          for (const key in responseObject) {
            if (key) {
              responses.push(`${key}${this.captionAndMessageSeparator} ${responseObject[key]}`);
            } else if (responseObject[key]) {
              responses.push(responseObject[key].toString());
            }
          }
        }
      }
      if (!responses.length && this.getResponseBody(data)) {
        responses.push(`${data.statusText}: ${this.getResponseBody(data).toString()}`);
      }
    }
    if (!responses.length) {
      responses.push(data.toString());
    }
    if (this.checkAccessDenied(data)) {
      responses.splice(
        0,
        0,
        `${this.accessDeniedMessageCaption}${this.captionAndMessageSeparator} ${this.accessDeniedMessageDetail}`,
      );
    }
    return responses;
  }

  public static findHttpResponseMessage(
    messageToFind: string,
    data: HttpResponse<any> | any,
    seachInCaptionOnly = true,
    includeCaptionInResult = false,
  ): string {
    const searchString = messageToFind.toLowerCase();
    const httpMessages = this.getHttpResponseMessage(data);
    for (const message of httpMessages) {
      const fullMessage = Utilities.splitInTwo(message, this.captionAndMessageSeparator);

      if (fullMessage.firstPart && fullMessage.firstPart.toLowerCase().indexOf(searchString) !== -1) {
        return includeCaptionInResult ? message : fullMessage.secondPart || fullMessage.firstPart;
      }
    }
    if (!seachInCaptionOnly) {
      for (const message of httpMessages) {
        if (message.toLowerCase().indexOf(searchString) !== -1) {
          if (includeCaptionInResult) {
            return message;
          } else {
            const fullMessage = Utilities.splitInTwo(message, this.captionAndMessageSeparator);
            return fullMessage.secondPart || fullMessage.firstPart;
          }
        }
      }
    }
    return null;
  }

  public static getResponseBody(response: HttpResponseBase) {
    if (response instanceof HttpResponse) {
      return response.body;
    }
    if (response instanceof HttpErrorResponse) {
      return response.error || response.message || response.statusText;
    }
  }

  public static checkNoNetwork(response: HttpResponseBase) {
    if (response instanceof HttpResponseBase) {
      return response.status === 0;
    }
    return false;
  }

  public static checkAccessDenied(response: HttpResponseBase) {
    if (response instanceof HttpResponseBase) {
      return response.status === 403;
    }
    return false;
  }

  public static checkNotFound(response: HttpResponseBase) {
    if (response instanceof HttpResponseBase) {
      return response.status === 404;
    }
    return false;
  }

  public static checkIsLocalHost(url: string, base?: string) {
    if (url) {
      const location = new URL(url, base);
      return location.hostname === 'localhost' || location.hostname === '127.0.0.1';
    }
    return false;
  }

  public static getQueryParamsFromString(paramString: string) {
    if (!paramString) {
      return null;
    }
    const params: { [key: string]: string } = {};
    for (const param of paramString.split('&')) {
      const keyValue = Utilities.splitInTwo(param, '=');
      params[keyValue.firstPart] = keyValue.secondPart;
    }
    return params;
  }

  public static splitInTwo(text: string, separator: string): { firstPart: string; secondPart: string } {
    const separatorIndex = text.indexOf(separator);
    if (separatorIndex === -1) {
      return { firstPart: text, secondPart: null };
    }
    const part1 = text.substr(0, separatorIndex).trim();
    const part2 = text.substr(separatorIndex + 1).trim();
    return { firstPart: part1, secondPart: part2 };
  }

  public static safeStringify(object) {
    let result: string;
    try {
      result = JSON.stringify(object);
      return result;
    } catch (error) {
      /* empty */
    }
    const simpleObject = {};
    for (const prop in object) {
      if (!Object.prototype.hasOwnProperty.call(object, prop)) {
        continue;
      }
      if (typeof object[prop] === 'object') {
        continue;
      }
      if (typeof object[prop] === 'function') {
        continue;
      }
      simpleObject[prop] = object[prop];
    }
    result = '[***Sanitized Object***]: ' + JSON.stringify(simpleObject);
    return result;
  }

  public static JSonTryParse(value: string) {
    try {
      return JSON.parse(value);
    } catch (e) {
      if (value === 'undefined') {
        return void 0;
      }
      return value;
    }
  }

  public static TestIsObjectEmpty(obj: any) {
    for (const prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        return false;
      }
    }
    return true;
  }

  public static TestIsUndefined(value: any) {
    return typeof value === 'undefined';
    // return value === undefined;
  }

  public static TestIsString(value: any) {
    return typeof value === 'string' || value instanceof String;
  }

  public static capitalizeFirstLetter(text: string) {
    if (text) {
      return text.charAt(0).toUpperCase() + text.slice(1);
    } else {
      return text;
    }
  }

  public static toTitleCase(text: string) {
    return text.replace(/\w\S*/g, (subString) => {
      return subString.charAt(0).toUpperCase() + subString.substr(1).toLowerCase();
    });
  }

  public static toLowerCase(items: string);
  // eslint-disable-next-line @typescript-eslint/unified-signatures
  public static toLowerCase(items: string[]);

  public static toLowerCase(items: any): string | string[] {
    if (items instanceof Array) {
      const loweredRoles: string[] = [];
      for (let i = 0; i < items.length; i++) {
        loweredRoles[i] = items[i].toLowerCase();
      }
      return loweredRoles;
    } else if (typeof items === 'string' || items instanceof String) {
      return items.toLowerCase();
    }
  }

  public static uniqueId() {
    return this.randomNumber(1000000, 9000000).toString();
  }

  public static uuidv4() {
    let d = new Date().getTime();
    if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
      d += performance.now();
    }
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      // eslint-disable-next-line no-bitwise
      const r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      // eslint-disable-next-line no-bitwise
      return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
  }

  public static randomNumber(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  public static baseUrl() {
    let base = '';
    if (window.location.origin) {
      base = window.location.origin;
    } else {
      base =
        window.location.protocol +
        '//' +
        window.location.hostname +
        (window.location.port ? ':' + window.location.port : '');
    }
    return base.replace(/\/$/, '');
  }

  public static printDateOnly(date: Date) {
    date = new Date(date);
    const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    const dayOfWeek = date.getDay();
    const dayOfMonth = date.getDate();
    let sup = '';
    const month = date.getMonth();
    const year = date.getFullYear();
    if (dayOfMonth === 1 || dayOfMonth === 21 || dayOfMonth === 31) {
      sup = 'st';
    } else if (dayOfMonth === 2 || dayOfMonth === 22) {
      sup = 'nd';
    } else if (dayOfMonth === 3 || dayOfMonth === 23) {
      sup = 'rd';
    } else {
      sup = 'th';
    }
    const dateString = dayNames[dayOfWeek] + ', ' + dayOfMonth + sup + ' ' + monthNames[month] + ' ' + year;
    return dateString;
  }

  public static printTimeOnly(date: Date) {
    date = new Date(date);
    let period = '';
    let minute = date.getMinutes().toString();
    let hour = date.getHours();
    period = hour < 12 ? 'AM' : 'PM';
    if (hour === 0) {
      hour = 12;
    }
    if (hour > 12) {
      hour = hour - 12;
    }
    if (minute.length === 1) {
      minute = '0' + minute;
    }
    const timeString = hour + ':' + minute + ' ' + period;
    return timeString;
  }

  public static printDate(date: Date, separator = 'at') {
    return `${Utilities.printDateOnly(date)} ${separator} ${Utilities.printTimeOnly(date)}`;
  }

  public static printFriendlyDate(date: Date, separator = '-') {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    const test = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    if (test.toDateString() === today.toDateString()) {
      return `Today ${separator} ${Utilities.printTimeOnly(date)}`;
    }
    if (test.toDateString() === yesterday.toDateString()) {
      return `Yesterday ${separator} ${Utilities.printTimeOnly(date)}`;
    } else {
      return Utilities.printDate(date, separator);
    }
  }

  public static printShortDate(date: Date, separator = '/', dateTimeSeparator = '-') {
    let day = date.getDate().toString();
    let month = (date.getMonth() + 1).toString();
    const year = date.getFullYear();
    if (day.length === 1) {
      day = '0' + day;
    }
    if (month.length === 1) {
      month = '0' + month;
    }
    return `${month}${separator}${day}${separator}${year} ${dateTimeSeparator} ${Utilities.printTimeOnly(date)}`;
  }

  public static parseDate(date): Date {
    if (date) {
      if (date instanceof Date) {
        return date;
      }
      if (typeof date === 'string' || date instanceof String) {
        if (date.search(/[a-su-z+]/i) === -1) {
          date = date + '';
        }
        const d = new Date(date);
        return d;
      }
      if (typeof date === 'number' || date instanceof Number) {
        return new Date(<any>date);
      }
    }
  }

  public static printDuration(start: Date, end: Date) {
    start = new Date(start);
    end = new Date(end);
    // get total seconds between the times
    let delta = Math.abs(start.valueOf() - end.valueOf()) / 1000;
    // calculate (and subtract) whole days
    const days = Math.floor(delta / 86400);
    delta -= days * 86400;
    // calculate (and subtract) whole hours
    const hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;
    // calculate (and subtract) whole minutes
    const minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;
    // what's left is seconds
    const seconds = delta % 60; // in theory the modulus is not required
    let printedDays = '';
    if (days) {
      printedDays = `${days} days`;
    }
    if (hours) {
      printedDays += printedDays ? `, ${hours} hours` : `${hours} hours`;
    }
    if (minutes) {
      printedDays += printedDays ? `, ${minutes} minutes` : `${minutes} minutes`;
    }
    if (seconds) {
      printedDays += printedDays ? ` and ${seconds} seconds` : `${seconds} seconds`;
    }
    if (!printedDays) {
      printedDays = '0';
    }
    return printedDays;
  }

  public static getAge(birthDate, otherDate) {
    birthDate = new Date(birthDate);
    otherDate = new Date(otherDate);
    let years = otherDate.getFullYear() - birthDate.getFullYear();
    if (
      otherDate.getMonth() < birthDate.getMonth() ||
      (otherDate.getMonth() === birthDate.getMonth() && otherDate.getDate() < birthDate.getDate())
    ) {
      years--;
    }
    return years;
  }

  public static searchArray(searchTerm: string, caseSensitive: boolean, ...values) {
    if (!searchTerm) {
      return true;
    }
    if (!caseSensitive) {
      searchTerm = Utilities.accentedStrip(searchTerm.toString().toLowerCase());
    }
    for (const value of values) {
      if (value != null) {
        let strValue = value.toString();
        if (!caseSensitive) {
          strValue = Utilities.accentedStrip(strValue.toLowerCase());
        }
        if (strValue.indexOf(searchTerm) !== -1) {
          return true;
        }
      }
    }
    return false;
  }

  public static moveArrayItem(array, oldIndex, newIndex) {
    while (oldIndex < 0) {
      oldIndex += this.length;
    }
    while (newIndex < 0) {
      newIndex += this.length;
    }
    if (newIndex >= this.length) {
      let k = newIndex - this.length;
      while (k-- + 1) {
        array.push(undefined);
      }
    }
    array.splice(newIndex, 0, array.splice(oldIndex, 1)[0]);
  }

  public static expandCamelCase(text: string) {
    if (!text) {
      return text;
    }
    return text
      .replace(/([A-Z][a-z]+)/g, ' $1')
      .replace(/([A-Z][A-Z]+)/g, ' $1')
      .replace(/([^A-Za-z ]+)/g, ' $1');
  }

  public static testIsAbsoluteUrl(url: string) {
    const r = new RegExp('^(?:[a-z]+:)?//', 'i');
    return r.test(url);
  }

  public static convertToAbsoluteUrl(url: string) {
    return Utilities.testIsAbsoluteUrl(url) ? url : '//' + url;
  }

  public static debounce(func: (...args) => any, wait: number, immediate?: boolean) {
    let timeout;
    return function () {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const context = this;
      // eslint-disable-next-line prefer-rest-params
      const args_ = arguments;
      const later = function () {
        timeout = null;
        if (!immediate) {
          func.apply(context, args_);
        }
      };
      const callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) {
        func.apply(context, args_);
      }
    };
  }

  public static accentedStrip(str: string): string {
    if (Utilities.latinMap === null) {
      Utilities.latinMap = JSON.parse(decodeURIComponent(escape(atob(Utilities.base64map))));
    }
    return str.replace(/[^A-Za-z0-9[\] ]/g, function (a) {
      return Utilities.latinMap[a] || a;
    });
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  public static appendSrcToDest(src: {}, dest: {}) {
    for (const key of Object.keys(src)) {
      dest[key] = src[key];
    }
  }

  public static unmask(val) {
    if (val && this.TestIsString(val)) {
      const n = val.replace(/\D+/g, '');
      return n;
    }
    return val;
  }

  public static unmaskDecimal(val) {
    if (val && this.TestIsString(val)) {
      const n = val.replace(/\./g, '').replace(/,/g, '.').replace(/R\$ /g, '');
      return n;
    }
    return val;
  }

  public static TF(group: FormGroup, name: string): boolean {
    const id = group.controls[name];
    if (!id || !id.value) {
      return false;
    }
    return true;
  }

  public static comparefunction(pa: any, pb: any, sortDirection: string) {
    const a = Utilities.accentedStrip(pa.toString().toLowerCase());
    const b = Utilities.accentedStrip(pb.toString().toLowerCase());
    const value = sortDirection === 'asc' ? a.localeCompare(b) : b.localeCompare(a);
    //console.log('Sorting:', value, pa, pb, sortDirection);
    return value;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public static recalculateTable(_grid: any) {}

  /**
   * Tratamento para transformar um blob em arquivo
   * @param resp
   */
  public static trataArquivoBlob(alertService: AlertService, resp: HttpResponse<Blob>) {
    return new Promise<void>((resolve) => {
      const contentDisposition = resp.headers.get('content-disposition');
      if (!contentDisposition) {
        if (resp.body.type === 'application/json') {
          (<Promise<any>>(<any>resp.body).text()).then((res) => {
            //console.log('BODY', res);
            const mmm = JSON.parse(res);
            alertService.error(mmm.message);
          });
        } else {
          alertService.error('Erro na geração do arquivo.');
        }
        resolve();
      } else {
        const filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
        const downloadURL = window.URL.createObjectURL(resp.body);
        const link = document.createElement('a');
        link.href = downloadURL;
        link.download = filename;
        // link.click();
        // this is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(downloadURL);
          link.remove();
          resolve();
        }, 100);
      }
    });
  }
  /**
   * Compara duas datas, para fins de classificação
   * @param valueA
   * @param valueB
   * @returns Retorna 0, 1, -1
   */
  public static compareToDates(valueA: string | number | Date, valueB: string | number | Date): number {
    try {
      let ta = new Date(valueA).getTime();
      if (isNaN(ta)) {
        ta = 0;
      }
      let tb = new Date(valueB).getTime();
      if (isNaN(tb)) {
        tb = 0;
      }
      if (ta < tb) {
        return -1;
      }
      if (ta > tb) {
        return 1;
      }
      return 0;
    } catch (error) {
      console.error('erro:' + error);
    }
  }

  public static truncateString(str: string, sz: number): string {
    if (str && str.length > sz) {
      return str.slice(0, sz) + '...';
    }
    return str;
  }
  //Utilities.parseOrNullDate()
  public static parseOrNullDate(src: string): Date | number {
    if (src) {
      const dt = new Date(src);
      if (dt.getTime()) {
        return dt;
      }
      return -1;
    }
    return null;
  }
  /**
   * Parse de data formato BR
   * @param dateTimeString dd/mm/yyyy HH:mm:ss
   * @returns - 2019-02-19T13:00:00.000Z
   */
  public static parseBrDateTime(dateTimeString: string): Date {
    //2019-02-19T13:00:00.000Z
    if (!dateTimeString && dateTimeString.length !== 19) {
      //console.log('Data inválida:', dateTimeString);
      throw 'Data inválida: ' + dateTimeString;
    }
    const [dateString, timeString] = dateTimeString.split(' ');
    const [day, month, year] = dateString.split('/');
    const [hour, minute, second] = timeString.split(':');
    const str = year + '-' + month + '-' + day + 'T' + hour + ':' + minute + ':' + second + 'Z';
    if (isNaN(Date.parse(str))) {
      //console.log('Data inválida:', dateTimeString);
      throw 'Data inválida: ' + dateTimeString;
    }
    const dateObj = new Date(str);
    return dateObj;
  }

  /**
   * Parse de data formato BR
   * @param dateTimeString dd/mm/yyyy
   * @returns - 2019-02-19T00:00:00.000Z
   */
  public static parseBrDate(dateString: string): Date {
    if (!dateString && dateString.length !== 10) {
      //console.log('Data inválida:', dateString);
      throw 'Data inválida: ' + dateString;
    }
    const [day, month, year] = dateString.split('/');
    const str = year + '-' + month + '-' + day;
    if (isNaN(Date.parse(str))) {
      //console.log('Data inválida:', dateString);
      throw 'Data inválida: ' + dateString;
    }
    const dateObj = new Date(str);
    return dateObj;
  }

  /**
   * Formata data:'dd/MM/yyyy HH:mm:ss'
   * @param item Data a ser fromatada
   * @returns
   */
  public static toDate(item: Date): string {
    if (item) {
      try {
        return formatDate(item, 'dd/MM/yyyy HH:mm:ss', 'pt-BR', 'UTC');
      } catch (error) {
        console.error('Error:', error);
      }
    }
    return '';
  }

  public static getTextParameter(parameters: ValueName[], name: string): string {
    if (parameters) {
      for (let i = 0; i < parameters.length; i++) {
        if (parameters[i].id === name) {
          return parameters[i].text;
        }
      }
    }
    return '';
  }

  public static setTextParameter(parameters: ValueName[], name: string, value: string): void {
    for (let i = 0; i < parameters.length; i++) {
      if (parameters[i].id === name) {
        parameters[i].text = value;
        return;
      }
    }
    parameters.push({ id: name, text: value });
  }

  public static deleteParameter(parameters: ValueName[], name: string): void {
    const indexOfObject = parameters.findIndex((object) => {
      return object.id === name;
    });
    if (indexOfObject !== -1) {
      parameters.splice(indexOfObject, 1);
    }
  }

  public static copyTextParameters(parameters: ValueName[]): ValueName[] {
    const ret: ValueName[] = [];
    if (parameters) {
      for (let i = 0; i < parameters.length; i++) {
        ret.push({ id: parameters[i].id, text: parameters[i].text });
      }
    }
    return ret;
  }

  public static extractParameters(route: ActivatedRoute): ValueName[] {
    //this.route.snapshot.queryParams['data']
    const params: ValueName[] = [];
    const param = route.snapshot.queryParams;
    if (param) {
      for (const key in param) {
        params.push({ id: key, text: param[key] });
      }
    }
    return params;
  }

  public static ScrollToElement(idelement: string): void {
    const element = document.getElementById(idelement);
    if (element != undefined) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }
  }

  public static GoToRotaWithParameters(router: Router, link: string, parameters: ValueName[]): void {
    const qp = {};
    if (parameters) {
      parameters.forEach((item) => {
        qp[item.id] = item.text;
      });
    }
    router.navigate([link], { queryParams: qp, skipLocationChange: true, replaceUrl: false });
  }
  /**
   *
   * @param modalService
   * @param content
   * @param windowClass 'tpx-custom-modal' = default.
   *                    'tpx-custom-modal-500' = 500px  de largura
   *                    'tpx-custom-modal-compacto'
   * @returns
   */
  public static OpenNewModal(modalService: NgbModal, content, windowClass: string = 'tpx-custom-modal'): NgbModalRef {
    const wpadrao = 'tpx-custom-modal' === windowClass ? null : windowClass;
    const modalRef: NgbModalRef = modalService.open(content, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
      windowClass: wpadrao,
    });
    if (wpadrao === null) {
      modalRef.shown.subscribe(() => {
        // Acessar o conteúdo do modal e contar o número de inputs
        const modalDialogElement = (modalRef as any)._windowCmptRef.location.nativeElement.querySelector(
          '.modal-dialog',
        );
        const numberOfInputs = modalDialogElement.querySelectorAll('input, div.row, i').length;
        // Calcular a largura baseada no número de inputs
        const width = Utilities.CalculateWidthBasedOnColumns(numberOfInputs);
        //console.log('Modal:', windowClass, numberOfInputs, width);
        // Aplicar a largura calculada ao modal
        if (modalDialogElement) {
          modalDialogElement.style.maxWidth = width;
        }
      });
    }
    //console.log('## Ativa modal:', content.name);
    return modalRef;
  }

  public static CalculateWidthBasedOnColumns(columns: number): string {
    const screenWidth = window.innerWidth;
    let colWidthPercentage;
    if (screenWidth >= 1200) {
      // Tamanho para telas grandes (>= 1200px)
      colWidthPercentage = Math.min((columns / 12) * 100, 70);
    } else if (screenWidth >= 992) {
      // Tamanho para telas médias (>= 992px)
      colWidthPercentage = Math.min((columns / 12) * 100, 75);
    } else if (screenWidth >= 768) {
      // Tamanho para tablets (>= 768px)
      colWidthPercentage = Math.min((columns / 12) * 100, 90); // Limite máximo de 90% para tablets
    } else {
      // Tamanho para telefones (< 768px)
      colWidthPercentage = Math.min((columns / 12) * 100, 95); // Limite máximo de 95% para telefones
    }
    const minWidth = 500; // Define o valor mínimo em pixels
    let calculatedWidth = Math.max((colWidthPercentage * screenWidth) / 100, minWidth);
    // Limitar a largura ao tamanho da tela
    calculatedWidth = Math.min(calculatedWidth, screenWidth * 0.95);
    return `${calculatedWidth}px`;
  }

  public static JoinListValues(translation: L10nTranslationService, tipo: string, value): string {
    if (value && Array.isArray(value) && value.length > 0) {
      const result = [];
      for (const elm in Object.keys(value)) {
        result.push(translation.translate(tipo + '.' + value[elm]));
      }
      return result.join(', ');
    }
    return value;
  }

  public static GetDeleteRowMessage(
    gridComponent: GridModelComponent,
    translateKey: string,
    row: any,
    names: string[],
  ): string {
    const texto = names
      .filter((key) => key !== 'action' && key !== 'select')
      .map((key) => this.createFieldHtml(gridComponent, translateKey, row, key))
      .join('');

    return `<div>${texto}</div>`;
  }

  private static createFieldHtml(
    gridComponent: GridModelComponent,
    translateKey: string,
    row: any,
    key: string,
  ): string {
    const value = gridComponent.DisplayField(key, row[key], row);
    const label = gridComponent.translation.translate(`${translateKey}.${key}`);
    return this.createHtmlSnippet(label, value);
  }

  private static createHtmlSnippet(label: string, value: string): string {
    return `
      <div class="d-flex align-items-center">
        <div class="w-error-label">${label}</div>
        <div class="px-1">:</div>
        <div class="w-error-ellipsis">${value}</div>
      </div>
    `;
  }
  /**
   * Função para calcular o contraste da cor do texto com a cor de fundo
   * @param bgColor Cor de fundo em formato hexadecimal (por exemplo, #ffffff)
   * @returns Cor do texto que oferece melhor contraste (preto ou branco)
   */
  public static GetContrastingTextColor(bgColor: string): string {
    // Remove o símbolo de hash (#) se existir
    if (bgColor.startsWith('#')) {
      bgColor = bgColor.slice(1);
    }

    // Converte a cor hexadecimal para valores RGB
    const r = parseInt(bgColor.slice(0, 2), 16);
    const g = parseInt(bgColor.slice(2, 4), 16);
    const b = parseInt(bgColor.slice(4, 6), 16);

    // Calcula o brilho da cor de fundo
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;

    // Retorna preto para fundo claro e branco para fundo escuro
    return brightness > 128 ? '#000000' : '#ffffff';
  }

  /**
   *
   * @param modalService
   * @param content
   * @param callback : (message: CloseModalResult) => {    }
   * @param windowClass
   */
  public static OpenNewModalWindow(
    modalService: NgbModal,
    content,
    callback: ModalCallbackFunction,
    windowClass: string = 'tpx-custom-modal',
  ): NgbModalRef {
    const modalRef = Utilities.OpenNewModal(modalService, content, windowClass);
    const view = modalRef.componentInstance;
    view.viewType = ViewTypeEnum.ADD;
    modalRef.result.then((result) => {
      if (result?.ok) {
        callback(result);
        if (result?.repeat) {
          Utilities.OpenNewModalWindow(modalService, content, callback);
        }
      }
    });
    return modalRef;
  }

  /**
   * Cria um modal diretamente a partir de um fragmento,
   * semelhante ao que um componente tipo ADD.
   * @param modalService
   * @param content
   * @param callback
   * @param bag
   * @param windowClass
   */
  public static OpenNewLoaderModalWindow(
    modalService: NgbModal,
    content,
    callback: ModalCallbackFunction,
    bag: BagParameter = null,
    windowClass: string = 'tpx-custom-modal',
  ): NgbModalRef {
    const modalRef = Utilities.OpenNewModal(modalService, LoaderModalComponent, windowClass);
    const modalComponent = modalRef.componentInstance as LoaderModalComponent;
    modalComponent.componentToLoad = content;
    modalComponent.componentLoaded.subscribe((instance) => {
      // You can now call methods or set properties on the instance
      if (instance) {
        if (instance.viewType) {
          instance.viewType = ViewTypeEnum.ADD;
        }
        if (bag) {
          if (typeof instance.setBagParameter === 'function') {
            instance.setBagParameter(bag);
          }
          if (typeof modalComponent.setBagParameter === 'function') {
            modalComponent.setBagParameter(bag);
          }
        }
      }
    });
    //
    modalRef.result.then((result) => {
      if (result?.ok) {
        callback(result);
        if (result?.repeat) {
          Utilities.OpenNewLoaderModalWindow(modalService, content, callback);
        }
      }
    });
    return modalRef;
  }

  public static isControlRequired(control: AbstractControl): boolean {
    if (control && control.validator) {
      const validators = control.validator({} as AbstractControl);
      if (validators && validators.required) {
        return true;
      }
    }
    return false;
  }

  public static addValidator(control: AbstractControl, validator: ValidatorFn) {
    const validators = control.validator ? Utilities.extractValidators(control.validator) : [];
    validators.push(validator);
    control.setValidators(validators);
  }

  public static removeValidator(control: AbstractControl, validatorToRemove: ValidatorFn) {
    const validators = control.validator ? Utilities.extractValidators(control.validator) : [];
    const index = validators.indexOf(validatorToRemove);
    if (index !== -1) {
      validators.splice(index, 1);
    }
    control.setValidators(validators);
  }

  public static extractValidators(validatorFn: ValidatorFn): ValidatorFn[] {
    const validator = validatorFn({} as AbstractControl);
    if (!validator) return [];

    const validators: ValidatorFn[] = [];
    if (validator.required) validators.push(Validators.required);
    if (validator.minlength) validators.push(Validators.minLength(validator.minlength.requiredLength));
    // Add other validators as needed
    return validators;
  }

  public static hasNonNullOrNonEmptyProperties(obj: any): boolean {
    if (obj == null || typeof obj !== 'object') {
      return false;
    }
    for (const key of Object.keys(obj)) {
      const value = obj[key];
      if (value != null) {
        if (typeof value === 'string' && value.trim() === '') {
          continue; // Ignore empty strings
        }
        return true; // Found a non-null or non-empty property
      }
    }
    return false;
  }
  public static toValueNameArray(src: object): ValueName[] {
    const lista: ValueName[] = [];
    for (const key of Object.keys(src)) {
      lista.push({ id: key, text: src[key] });
    }
    return lista;
  }
  public static createFieldIdTable(form: FormGroup, instanceId: string): any {
    const table = {};
    table['form'] = `form-${instanceId}`;
    Object.keys(form.controls).forEach((key) => {
      table[key] = `${key}-${instanceId}`;
    });
    return table;
  }
}
