<!-- {{GENERATE}} -->
<!-- #I-#start-container/R -->
<ng-container [formGroup]="CrudForm">
  <!-- #E-#start-container -->
  <!-- #I-#header-form/R -->
  <div [class.modal-header]="isModal">
    <div class="t-header-title" l10nTranslate>PersonalizarHome.HEADERTITLE</div>
    @if (isModal) {
      <button type="button" class="btn-close" (click)="OnCloseModal(false)" aria-label="Close"></button>
    }
  </div>
  <!-- class.modal-header -->
  <!-- #E-#header-form -->
  <!-- #I-#start-body/R -->
  <div [class.modal-body]="isModal">
    <!-- #E-#start-body -->
    <!-- #I-#field-bean/R -->
    <div class="w-100">
      <app-personalizar-home-bean-fragment
        #vbean
        [EditMode]="IsEditMode()"
        [CrudForm]="CrudForm.get('bean')"
      ></app-personalizar-home-bean-fragment>
    </div>
    <!-- #E-#field-bean -->
    <!-- #I-#end-body/R -->
  </div>
  <!-- class.modal-body -->
  <!-- #E-#end-body -->
  <!-- #I-#footer-form/R -->
  <div [class.modal-footer]="isModal">
    <div class="d-flex align-content-start flex-wrap">
      @if (isModal) {
        <button type="button" class="btn btn-secondary me-2" (click)="OnCloseModal(false)" l10nTranslate>
          <i class="fa fa-times"></i> PersonalizarHome.BTN-BACK
        </button>
      }
      <button type="button" class="btn btn-primary" (click)="OnProcessar()" [disabled]="!CrudForm.valid" l10nTranslate>
        <i class="fa fa-save"></i> PersonalizarHome.BTN-ENTER
      </button>
    </div>
  </div>
  <!-- class.modal-footer -->
  <!-- #E-#footer-form -->
  <!-- #I-#end-container/R -->
</ng-container>
<!-- #E-#end-container -->
<!--
-->
