// {{GENERATE}}
import { CurrencyPipe, DatePipe } from '@angular/common';
import { AfterViewChecked, AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import {
  L10N_LOCALE,
  L10nLocale,
  L10nTranslateDirective,
  L10nTranslatePipe,
  L10nTranslationService,
} from 'angular-l10n';
import { ViewTypeEnum } from 'src/app/core/helpers/ViewTypeEnum';
import { Constants } from 'src/app/core/helpers/constants';
import { SearchBoxComponent } from 'src/app/core/helpers/search-box.component';
import { GridModelComponent } from 'src/app/core/models/grid-model.component';
import { AlertService, DialogType } from 'src/app/core/services/alert.service';
import { Utilities } from 'src/app/core/services/utilities';
import { TbUserBean } from '../../../models/TbUserBean';
import { AdminUserActionService } from '../../../services/admin-user-action.service';
import { AdminUserActionAddComponent } from './admin-user-action-add.component';

// #region #pre-definition#.
// #endregion

@Component({
  standalone: true,
  selector: 'app-admin-user-action-grid',
  templateUrl: './admin-user-action-grid.component.html',
  imports: [
    ReactiveFormsModule,
    L10nTranslateDirective,
    L10nTranslatePipe,
    NgSelectModule,
    NgbModule,
    MatTableModule,
    MatCheckboxModule,
    MatPaginator,
    MatPaginatorModule,
    MatSort,
    MatSortModule,
    SearchBoxComponent,
  ],
})
export class AdminUserActionGridComponent implements OnInit, AfterViewChecked, GridModelComponent, AfterViewInit {
  // #region #properties#.
  // #endregion

  ColumnMode = ColumnMode;
  SelectionType = SelectionType;
  lastsearchtext = '';
  checkedTodas = true;
  public displayedColumns: string[] = ['username', 'name', 'status', 'email', 'roles', 'action'];
  rows: MatTableDataSource<TbUserBean>;
  rowsCache: TbUserBean[];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  loadingIndicator: boolean;
  messages = Constants.messages;

  // #region #constructor#cmp#.
  // CODGEN {{R}}
  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private alertService: AlertService,
    private crudService: AdminUserActionService,
    private modalService: NgbModal,
    public translation: L10nTranslationService,
  ) {
    //
    this.rows = new MatTableDataSource<TbUserBean>([]);
  }
  // #endregion

  // #region #ngoninit.
  // CODGEN {{R}}
  ngOnInit(): void {
    this.OnInitInvokeService();
  }
  // #endregion

  // #region #ngafterviewinit.
  // CODGEN {{R}}
  ngAfterViewInit(): void {
    // console.log('ngAfterViewInit');
    this.rows.paginator = this.paginator;
    this.rows.sort = this.sort;
  }
  // #endregion

  // #region #ngafterviewchecked.
  // CODGEN {{R}}
  ngAfterViewChecked(): void {}
  // #endregion

  // #region #oninitinvokeservice.
  // CODGEN {{R}}
  OnInitInvokeService() {
    this.loadingIndicator = true;

    this.crudService.GetAllUser(this.checkedTodas ? 'TODAS' : '').subscribe({
      next: (data) => {
        this.rowsCache = [...data];
        if (this.lastsearchtext && this.lastsearchtext !== '') {
          this.OnSearchChanged(this.lastsearchtext);
        } else {
          this.rows.data = this.rowsCache;
        }
        this.loadingIndicator = false;
      },
      error: (error) => {
        this.loadingIndicator = false;
        this.alertService.error(error);
      },
    });
  }
  // #endregion

  // #region #ondeleterow.
  // CODGEN {{R}}
  OnDeleteRow(row: TbUserBean) {
    this.alertService.showDialog(
      this.translation.translate('AdminUserActionGrid.DELETE-TITLE'),
      Utilities.GetDeleteRowMessage(this, 'AdminUserActionGrid', row, this.displayedColumns),
      DialogType.confirm,
      () => this.OnDeleteRowHelper(row),
      () => {
        //console.log('Cancelado');
      },
    );
  }
  // #endregion

  // #region #ondeleterowhelper.
  // CODGEN {{R}}
  private OnDeleteRowHelper(row: TbUserBean) {
    this.crudService.RemoveUser(row.iduser).subscribe({
      next: () => {
        this.rowsCache = this.rowsCache.filter((item) => item !== row);
        this.rows.data = this.rowsCache;
      },
      error: (error) => {
        this.alertService.error(error);
      },
    });
  }
  // #endregion

  // #region #onsearchchanged.
  // CODGEN {{R}}
  OnSearchChanged(value: string) {
    this.lastsearchtext = value;
    this.rows.data = this.rowsCache.filter((r) =>
      Utilities.searchArray(
        value,
        false,
        this.DisplayField('username', r.username, r),
        this.DisplayField('name', r.name, r),
        this.DisplayField('status', r.status, r),
        this.DisplayField('email', r.email, r),
        this.DisplayField('roles', r.roles, r),
      ),
    );
  }
  // #endregion

  // #region #displayfield.
  // CODGEN {{R}}
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  DisplayField(fieldname: string, value, _row: TbUserBean) {
    switch (fieldname) {
      case 'username':
        return value;
      case 'name':
        return value;
      case 'status':
        return this.translation.translate('StatusEnum.' + value);
      case 'email':
        return value;
      case 'roles':
        return Utilities.JoinListValues(this.translation, 'AuthorityEnum', value);
      default:
        return value;
    }
  }
  // #endregion

  // #region #onaddelement.
  // CODGEN {{R}}
  OnAddElement() {
    const modalRef = Utilities.OpenNewModal(this.modalService, AdminUserActionAddComponent, 'tpx-custom-modal');
    const view: AdminUserActionAddComponent = modalRef.componentInstance;
    view.viewType = ViewTypeEnum.ADD;
    modalRef.result.then((result) => {
      if (result?.ok) {
        this.OnInitInvokeService();
        if (result?.repeat) {
          this.OnAddElement();
        }
      }
    });
  }
  // #endregion

  // #region #oneditrow.
  // CODGEN {{R}}
  OnEditRow(row: TbUserBean) {
    const modalRef = Utilities.OpenNewModal(this.modalService, AdminUserActionAddComponent, 'tpx-custom-modal');
    const view: AdminUserActionAddComponent = modalRef.componentInstance;
    view.viewType = ViewTypeEnum.READ;
    view.registro = row;
    modalRef.result.then((result) => {
      if (result?.ok) {
        this.OnInitInvokeService();
      }
    });
  }
  // #endregion

  // #region #onactivatedatatable.
  // CODGEN {{R}}
  OnActivateDatatable(event) {
    if (event.type === 'dblclick') {
      event.cellElement.blur();
      this.OnEditRow(event.row);
    }
  }
  // #endregion

  // #region #onemailnoticeschange.
  // CODGEN {{R}}
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  OnEmailnoticesChange(_evt) {}
  // #endregion

  // #region #onwhatsappnoticechange.
  // CODGEN {{R}}
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  OnWhatsappnoticeChange(_evt) {}
  // #endregion

  // #region #ontelegrannoticechange.
  // CODGEN {{R}}
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  OnTelegrannoticeChange(_evt) {}
  // #endregion

  // #region #onmostrarativas.
  // CODGEN {{R}}
  OnMostrarAtivas(target: EventTarget | null): void {
    const input = target as HTMLInputElement;
    this.checkedTodas = input.checked;
    this.OnInitInvokeService();
  }
  // #endregion

  // #region #routine-definitions#.
  // #endregion
}
