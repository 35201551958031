// {{GENERATE}}
// @CRUD
// @GRID
// @HOST
// @Route:/admin/user/action/grid
// URL:/admin/user
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RecuperacaoSenhaBean } from 'src/app/core/models/RecuperacaoSenhaBean';
import { RequestDataBean } from 'src/app/core/models/RequestDataBean';
import { ResponseDataBean } from 'src/app/core/models/ResponseDataBean';
import { SelectOptionList } from 'src/app/core/models/SelectOptionList';
import { HistoricoBean } from '../models/HistoricoBean';
import { StoreUserAvatarRequest } from '../models/StoreUserAvatarRequest';
import { TbUserBean } from '../models/TbUserBean';
import { UserPainelConfigBean } from '../models/UserPainelConfigBean';
import { UserRegistration } from '../models/UserRegistration';

@Injectable()
export abstract class AdminUserActionService {
  // #region #getalluser.
  // CODGEN {{R}}
  abstract GetAllUser(par: string): Observable<TbUserBean[]>;
  // #endregion

  // #region #crud#createuser.
  // CODGEN {{R}}
  abstract CreateUser(bean: TbUserBean): Observable<TbUserBean>;
  // #endregion

  // #region #crud#updateuser.
  // CODGEN {{R}}
  abstract UpdateUser(bean: TbUserBean): Observable<void>;
  // #endregion

  // #region #crud#getreaduser.
  // CODGEN {{R}}
  abstract GetReadUser(iduser: number): Observable<TbUserBean>;
  // #endregion

  // #region #crud#removeuser.
  // CODGEN {{R}}
  abstract RemoveUser(iduser: number): Observable<void>;
  // #endregion

  // #region #getselectoptions.
  // CODGEN {{R}}
  abstract GetSelectOptions(par: string, opcao: string): Observable<SelectOptionList>;
  // #endregion

  // #region #cmdselectlist.
  // CODGEN {{R}}
  abstract CmdSelectList(bean: SelectOptionList): Observable<SelectOptionList>;
  // #endregion

  // #region metodo#find-all-history.
  // CODGEN {{R}}
  abstract FindAllHistory(username: string, tipo: string): Observable<HistoricoBean[]>;
  // #endregion

  // #region metodo#start-registration.
  // CODGEN {{R}}
  abstract StartRegistration(bean: RequestDataBean): Observable<ResponseDataBean>;
  // #endregion

  // #region metodo#confirm-registration.
  // CODGEN {{R}}
  abstract ConfirmRegistration(bean: RequestDataBean): Observable<UserRegistration>;
  // #endregion

  // #region metodo#finalize-registration.
  // CODGEN {{R}}
  abstract FinalizeRegistration(bean: UserRegistration): Observable<ResponseDataBean>;
  // #endregion

  // #region metodo#update-user-profie.
  // CODGEN {{R}}
  abstract UpdateUserProfie(user: TbUserBean): Observable<ResponseDataBean>;
  // #endregion

  // #region metodo#store-user-avatar.
  // CODGEN {{R}}
  abstract StoreUserAvatar(par: StoreUserAvatarRequest): Observable<ResponseDataBean>;
  // #endregion

  // #region metodo#recuperacao-senha.
  // CODGEN {{R}}
  abstract RecuperacaoSenha(par: RecuperacaoSenhaBean): Observable<RecuperacaoSenhaBean>;
  // #endregion

  // #region metodo#get-painel-config.
  // CODGEN {{R}}
  abstract GetPainelConfig(nome: string): Observable<UserPainelConfigBean>;
  // #endregion

  // #region metodo#save-painel-config.
  // CODGEN {{R}}
  abstract SavePainelConfig(painel: UserPainelConfigBean): Observable<ResponseDataBean>;
  // #endregion

  // #region #declarations#.
  // #endregion
}
