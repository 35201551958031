import { Component, OnDestroy, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { BreadcrumpComponent } from '../header-principal/breadcrumb.component';
import { HeaderPrincipalComponent } from '../header-principal/header-principal.component';
import { MenuComponent } from '../menu/menu.component';
import { AdItem } from '../painel-ad/ad-item';
import { AdService } from '../painel-ad/ad.service';
import { Subscription } from 'rxjs';

import { PainelAdComponent } from '../painel-ad/painel-ad.component';

@Component({
  selector: 'app-home-layout',
  templateUrl: 'home.layout.component.html',
  styleUrls: ['home.layout.component.css'],
  standalone: true,
  imports: [HeaderPrincipalComponent, MenuComponent, BreadcrumpComponent, RouterOutlet, PainelAdComponent],
})
export class HomeLayoutComponent implements OnInit, OnDestroy {
  /** AD */
  private subscriptions: Subscription[] = []; // Armazena todas as inscrições
  adsList: AdItem[] = [];

  constructor(private adService: AdService) {}

  ngOnInit() {
    this.adsList = this.adService.getAdsView();
    const subscription = this.adService.getObservableEvent().subscribe((evt) => {
      if (evt.description && evt.description.href === 'home') {
        switch (evt.cmd) {
          case 'CLEAR':
          case 'ADD':
            this.adsList = this.adService.getAdsView();
            break;
          default:
            break;
        }
      }
    });
    this.subscriptions.push(subscription);
  }

  ngOnDestroy(): void {
    // Cancela todas as inscrições para evitar vazamentos de memória
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
