import { Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbDateStruct, NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-month-year-picker',
  standalone: true,
  imports: [NgbTooltipModule, NgbModule],
  templateUrl: './month-year-picker.component.html',
  styleUrls: ['./month-year-picker.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MonthYearPickerComponent),
      multi: true,
    },
  ],
})
export class MonthYearPickerComponent implements ControlValueAccessor {
  date: NgbDateStruct;
  value: string;

  private onChange: (value: string) => void;
  private onTouched: () => void;

  constructor() {
    this.setTodayValues();
  }

  writeValue(value: string): void {
    if (value) {
      const [month, year] = value.split('/').map(Number);
      this.date = { year, month, day: 1 };
      this.value = value;
    } else {
      this.setTodayValues();
    }
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    // Add code to handle the disabled state if necessary
  }

  setTodayValues(): void {
    const today = new Date();
    this.date = { year: today.getFullYear(), month: today.getMonth() + 1, day: 1 };
    this.value = this.formatDate(this.date);
  }

  onPrevMonth() {
    if (this.date.month === 1) {
      this.date.month = 12;
      this.date.year -= 1;
    } else {
      this.date.month -= 1;
    }
    this.updateValue();
  }

  onNextMonth() {
    if (this.date.month === 12) {
      this.date.month = 1;
      this.date.year += 1;
    } else {
      this.date.month += 1;
    }
    this.updateValue();
  }

  private updateValue() {
    this.value = this.formatDate(this.date);
    if (this.onChange) {
      this.onChange(this.value);
    }
    if (this.onTouched) {
      this.onTouched();
    }
  }

  private formatDate(date: NgbDateStruct): string {
    const month = date.month < 10 ? `0${date.month}` : date.month;
    return `01/${month}/${date.year}`;
  }

  get monthYear(): string {
    return `${this.month} ${this.year}`;
  }

  get month(): string {
    const months = [
      'janeiro',
      'fevereiro',
      'março',
      'abril',
      'maio',
      'junho',
      'julho',
      'agosto',
      'setembro',
      'outubro',
      'novembro',
      'dezembro',
    ];
    return `${months[this.date.month - 1]}`;
  }

  get year(): string {
    return `${this.date.year}`;
  }

  doClickDataAtual(): void {
    this.setTodayValues();
  }
}
