<div class="wrapper">
  <app-header-principal></app-header-principal>
  <aside id="aside-container" class="main-sidebar sidebar-primary border-end">
    <div class="menu-topo">
      <app-menu></app-menu>
    </div>
  </aside>
  <div class="content-wrapper" id="body-row">
    <div class="content-fluid">
      <div class="w-100 d-flex flex-row">
        <!-- MAIN -->
        <div id="id-home-content" class="home-content w-100 home-content-margin">
          <div>
            <div class="w-100 d-flex">
              <div class="principal-container">
                @for (item of adsList; track item; let i = $index) {
                  <div>
                    <div class="row" style="min-width: 500px">
                      <app-painel-ad [ads]="item"> </app-painel-ad>
                    </div>
                  </div>
                }
              </div>
              <div id="principal-container" class="principal-container flex-fill">
                <router-outlet></router-outlet>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Main Col END -->
  </div>
</div>
