import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FavoritosService } from '../../services/favoritos.service';
import { AdItem } from '../painel-ad/ad-item';
import { AdService } from '../painel-ad/ad.service';
import { PainelAdComponent } from '../painel-ad/painel-ad.component';
import { AdPainelDescription } from '../../models/AdPainelDescription';
import { DataService } from '../../services/data.service';
import { MonthYearPickerComponent } from '../../accessors/month-year-picker.component';
import { NgbModal, NgbModalRef, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { PersonalizarHomeComponent } from 'src/app/modules/admin/components/user/personalizar-home/personalizar-home.component';

@Component({
  selector: 'app-home',
  templateUrl: 'home.component.html',
  styleUrls: ['./home.component.css'],
  standalone: true,
  imports: [NgSelectModule, PainelAdComponent, MonthYearPickerComponent, NgbTooltipModule],
})
export class HomeComponent implements OnInit, OnDestroy, AfterViewInit {
  private subscriptions: Subscription[] = []; // Armazena todas as inscrições
  /** AD */
  adsList: AdItem[] = [];
  descricaoPainel: AdPainelDescription;

  constructor(
    private router: Router,
    private favoritosService: FavoritosService,
    private adService: AdService,
    private dataService: DataService,
    private modalService: NgbModal,
  ) {}

  ngAfterViewInit(): void {}

  ngOnInit() {
    // START LAST TRANSACTION
    //if (!environment.production) {
    // TESTE
    //this.router.navigate(['/tb/user-profile-form'], {
    //  queryParams: { data: '2019-03-07T12:30:00Z', rotina: 'ME4102D' },
    //});
    //EditarFotoPerfilFormComponent.OpenNewModal(this.modalService);
    // }
    this.carregaCards();
  }

  ngOnDestroy(): void {
    // Cancela todas as inscrições para evitar vazamentos de memória
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  private carregaCards(): void {
    this.adsList.slice(0, this.adsList.length);
    //this.dataService.clearPainel();
    this.descricaoPainel = { href: 'home', cards: [] };
    for (let n = 0; n < 12; n++) {
      this.descricaoPainel.cards.push({ name: 'app-change-password', key: '', painel: '' });
    }
    const ads = AdService.getAdsPainel(this.descricaoPainel);
    if (ads) {
      ads.forEach((element) => {
        this.adsList.push(element);
      });
    }
  }

  onPersonalizarHome(): void {
    const ref: NgbModalRef = PersonalizarHomeComponent.OpenNewModal(this.modalService);
  }
}
