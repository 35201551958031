<div class="app-content content">
  <div class="d-flex flex-nowrap">
    <div class="d-flex flex-column flex-shrink-0 bg-light" style="width: 6.5rem">
      <app-month-year-picker></app-month-year-picker>
      <button
        type="button"
        class="btn btn-sm"
        (click)="onPersonalizarHome()"
        ngbTooltip="personalizas paineis"
        placement="bottom"
      >
        <i class="fa fa-cog fa-2x" aria-hidden="true"></i>
      </button>
    </div>

    <div class="gridx w-100">
      @for (item of adsList; track item.data.id; let i = $index) {
        <div class="item" [class]="'item' + i">
          {{ 'item-' + i }}
          <!--
        <app-painel-ad [ads]="item"> </app-painel-ad>
        --></div>
      }
    </div>
  </div>
</div>
