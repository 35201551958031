import { Component, Input, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AdItem } from './ad-item';
import { AdComponent } from './ad.component';
import { AdDirective } from './ad.directive';

@Component({
  selector: 'app-painel-ad',
  templateUrl: './painel-ad.component.html',
  styleUrls: ['./painel-ad.component.css'],
  standalone: true,
  imports: [FormsModule],
})
export class PainelAdComponent implements OnInit, OnDestroy {
  @Input() ads: AdItem;
  @ViewChild(AdDirective, { static: true }) adHost: AdDirective;

  constructor(private viewContainerRef: ViewContainerRef) {}

  ngOnInit(): void {
    this.loadComponent();
  }

  ngOnDestroy(): void {}

  loadComponent() {
    const adItem = this.ads;
    const componentRef = this.viewContainerRef.createComponent(adItem.component);
    adItem.data.instance = <AdComponent>componentRef.instance;
  }
}
