<!-- {{GENERATE}} -->
<ng-container [formGroup]="CrudForm">
  <!-- #I-#header-form/R -->
  <div>
    <!-- <div class="t-header-title" l10nTranslate>PersonalizarHomeBeanFragment.HEADERTITLE</div> -->
  </div>
  <!-- header -->
  <!-- #E-#header-form -->
  <!-- #I-#begin-row-one/R -->
  <div class="row">
    <!-- #E-#begin-row-one -->
    <!-- #I-#field-numcolunas/R -->
    <div class="col-sm-6 col-md-6 col-lg-3 mb-3">
      @for (item of numcolunasRList; track item) {
        <div class="form-check form-check-inline">
          <input
            type="radio"
            formControlName="numcolunas"
            class="form-check-input"
            [attr.id]="GetId('ck-numcolunas')"
            [value]="item.value"
          />
          <label class="form-check-label" [for]="GetId('ck-numcolunas')" l10nTranslate
            >PersonalizarHomeBeanFragment.numcolunas-{{ item.value }}</label
          >
        </div>
      }
    </div>
    <!-- #E-#field-numcolunas -->
    <!-- #I-#field-opcoes/R -->
    <div class="col-sm-12 col-md-6 mb-3">
      <label [for]="fieldIdTable['opcoes']" class="form-label" l10nTranslate>PersonalizarHomeBeanFragment.opcoes</label>
      <input
        type="text"
        [attr.id]="fieldIdTable['opcoes']"
        formControlName="opcoes"
        class="form-control"
        [ngClass]="{ 'is-invalid': f.opcoes.invalid && (f.opcoes.dirty || f.opcoes.touched) }"
      />
    </div>
    <!-- #E-#field-opcoes -->
    <!-- #I-#end-row-one/R -->
  </div>
  <!-- #E-#end-row-one -->
</ng-container>
<!--
-->
