import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AdComponent } from './ad.component';

@Component({
  standalone: true,
  imports: [FormsModule],
  template: `
    <div class="job-ad">
      <div>{{ data.headline }}</div>
      {{ data.body }}
    </div>
  `,
})
export class HeroJobAdComponent implements AdComponent {
  @Input() data: any;
}
